import React from 'react';

const WorkWithMe = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white py-16 px-4 sm:px-6 lg:px-8">
      
      {/* Work With Me Title */}
      <h1 className="text-6xl font-extrabold text-gray-900 text-center mt-2">Work With Me</h1>

      <p className="text-lg text-gray-600 mt-8 text-center max-w-3xl">
        I partner with businesses to deliver customized technology solutions, bridging the gap between vision and execution. From innovative startups to established enterprises, let's collaborate to build, innovate, and grow together.
      </p>

      {/* Sections */}
      <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 w-full lg:w-5/6">
        
        {/* Collaboration */}
        <div className="p-8 bg-gradient-to-r from-purple-100 to-pink-50 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
          <h4 className="text-2xl font-bold text-gray-800">Collaboration</h4>
          <p className="mt-4 text-gray-500">
            I work closely with you, ensuring open communication and shared goals, to build technology that drives your business forward.
          </p>
        </div>

        {/* Innovation */}
        <div className="p-8 bg-gradient-to-r from-blue-100 to-teal-50 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
          <h4 className="text-2xl font-bold text-gray-800">Innovation</h4>
          <p className="mt-4 text-gray-500">
            By leveraging the latest technologies, I help you stay ahead of the curve, delivering cutting-edge solutions that are both creative and functional.
          </p>
        </div>

        {/* Reliability */}
        <div className="p-8 bg-gradient-to-r from-yellow-100 to-orange-50 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
          <h4 className="text-2xl font-bold text-gray-800">Reliability</h4>
          <p className="mt-4 text-gray-500">
            You can count on me to deliver quality work on time and within budget, prioritizing transparency and consistency at every step.
          </p>
        </div>

        {/* Growth */}
        <div className="p-8 bg-gradient-to-r from-green-100 to-lime-50 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
          <h4 className="text-2xl font-bold text-gray-800">Growth</h4>
          <p className="mt-4 text-gray-500">
            I am committed to supporting your growth, providing solutions that scale as your business expands.
          </p>
        </div>

        {/* Tutoring & Mentoring */}
        <div className="col-span-1 md:col-span-2 lg:col-span-4 p-8 bg-gradient-to-r from-indigo-100 to-violet-50 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
          <h4 className="text-2xl font-bold text-gray-800">Tutoring & Mentoring</h4>
          <p className="mt-4 text-gray-500">
            With a passion for education, I offer tutoring and mentoring in tech subjects, helping learners and professionals alike achieve their career goals through personalized guidance and support.
          </p>
        </div>
      </div>

      {/* Call to Action */}
      <div className="text-center mt-20">
        <h2 className="text-4xl font-extrabold text-gray-800">Let's Bring Your Ideas to Life</h2>
        <p className="text-lg text-gray-600 mt-6 max-w-xl mx-auto">
          I'm ready to collaborate with you on your next big project. Whether it's software development, cloud solutions, or consulting, I’m here to help. Let's connect and discuss how I can bring value to your team.
        </p>
        <button className="mt-10 bg-gradient-to-r from-purple-500 to-pink-500 text-white px-8 py-4 rounded-lg text-xl font-semibold shadow-lg hover:bg-gradient-to-l hover:from-pink-500 hover:to-purple-500 transition duration-300">
          Get In Touch
        </button>
      </div>

    </div>
  );
};

export default WorkWithMe;
