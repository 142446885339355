
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Blog from './components/Blog';
import Blogpost from './components/Blogpost';
import Header from'./components/Header';
import Auth from './components/Auth';
import Registration from './components/Registration';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css'; // Importing the CSS file



const App = () =>{
  return(
      
        <div >
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog"  element={<Blog/>} />
            <Route path="/blog/:id" element={<Blogpost/>} />
            <Route path="/auth" element={<Auth/>} />
            <Route path="/registration" element={<Registration/>} />
            <Route path="/contact" element={<Contact/>} />
          </Routes>
          <Footer />
        </div>
    
  );
}

export default App;