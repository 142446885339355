import React from 'react';
import avatar from '../assets/img/avatar.png';
import pythonIcon from '../assets/img/python.svg';
import javascriptIcon from '../assets/img/javascript.svg';
import awsIcon from '../assets/img/aws.svg';
import gcpIcon from '../assets/img/gcp.svg';
import terraformIcon from '../assets/img/terraform.svg';
import githubIcon from '../assets/img/github.svg';
import vscodeIcon from '../assets/img/vscode.svg';

const AboutMe = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-white to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      
      {/* Introduction */}
      <h1 className="text-5xl font-bold text-gray-900 text-center mt-2">About Me</h1>
      
      <div className="flex flex-col lg:flex-row items-center mt-10 lg:space-x-10">
        
        {/* Image Section */}
        <div className="rounded-xl shadow-lg overflow-hidden w-72 h-72 lg:w-96 lg:h-96 mb-8 lg:mb-0">
          <img
            src={avatar}
            alt="Profile"
            className="object-cover w-full h-full"
          />
        </div>

        {/* About Text and Info Cards */}
        <div className="flex flex-col items-center lg:items-start text-center lg:text-left">
          <p className="text-lg text-gray-600 mb-6">
            I am an experienced Software Developer with over 7 years of professional expertise in the field. Throughout my career, I have had the privilege of collaborating with prestigious organizations, contributing to their success and growth.
          </p>

          {/* Info Cards */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6 lg:mt-0 w-full">
            {/* Languages Card */}
            <div className="border rounded-lg p-6 shadow-md bg-white hover:border-[#e42ff5]">
              <div className="flex items-center mb-4">
                {/* Programming Languages Icon */}
                <svg className="w-6 h-6 text-accent mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M9.75 4.75v14.5L2.5 12l7.25-7.25zm4.5 0L21.5 12l-7.25 7.25V4.75z"/>
                </svg>
                <h4 className="text-lg font-semibold text-black">Languages</h4>
              </div>
              <p className="text-gray-500">Python, Django, JavaScript, React Js</p>
            </div>

            {/* Education Card */}
            <div className="border rounded-lg p-6 shadow-md bg-white hover:border-[#e42ff5]">
              <div className="flex items-center mb-4">
                {/* Education Icon */}
                <svg className="w-6 h-6 text-accent mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M12 2L1 8l11 6 9-4.9V18h2V9.1L12 2zm-1.3 12.4l-7.3-4L12 6.4l8.6 4.7-7.3 3.8zM17 19h-2v2h2v-2zm-4 0H9v2h4v-2z"></path>
                </svg>
                <h4 className="text-lg font-semibold text-black">Education</h4>
              </div>
              <p className="text-gray-500">-- N.Dip Computer Systems Engineering</p>
              <p className="text-gray-500">-- MSc - courses in Applied Mathematics and Cryptography</p>
            </div>

            {/* Projects Card */}
            <div className="border rounded-lg p-6 shadow-md bg-white hover:border-[#e42ff5]">
              <div className="flex items-center mb-4">
                {/* Projects Icon */}
                <svg className="w-6 h-6 text-accent mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M10 3a7 7 0 100 14 7 7 0 000-14zM9 7h2v4H9V7zm0 6h2v2H9v-2z"></path>
                </svg>
                <h4 className="text-lg font-semibold">Interests</h4>
              </div>
              <p className="text-gray-500">-- Mentoring</p>
              <p className="text-gray-500">-- Tutoring</p>
            </div>
          </div>
      
            <h3 className="text-lg font-semibold text-gray-500 mb-4 lg:mb-0 lg:mr-4 mt-4">Tools I Use</h3>
            <div className="flex flex-wrap justify-center lg:justify-start space-x-4 mt-4 items-center w-full">
            <img 
                src={pythonIcon} 
                alt="Python" 
                className="w-14 h-14 border-2 p-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110"
            />
            <img 
                src={javascriptIcon} 
                alt="JavaScript" 
                className="w-14 h-14 border-2 p-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110"
            />
            <img 
                src={awsIcon} 
                alt="AWS" 
                className="w-14 h-14 border-2 p-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110"
            />
            <img 
                src={gcpIcon} 
                alt="GCP" 
                className="w-14 h-14 border-2 p-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110"
            />
            <img 
                src={terraformIcon} 
                alt="Terraform" 
                className="w-14 h-14 border-2 p-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110"
            />
            <img 
                src={githubIcon} 
                alt="GitHub" 
                className="w-14 h-14 border-2 p-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110"
            />
            <img 
                src={vscodeIcon} 
                alt="VS Code" 
                className="w-14 h-14 border-2 p-2 rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-110"
            />
            </div>


          </div>
        </div>
      </div>
    
  );
};

export default AboutMe;
