import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logoTWM.png'; 

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false); // Close the menu when a menu item is clicked
  };

  return (
    <header className='flex py-4 px-4 sm:px-10 bg-white font-[sans-serif] min-h-[70px] tracking-wide relative z-50'>
      <div className='flex flex-wrap items-center justify-between gap-5 w-full'>
        <Link to="/"><img src={logo} alt="logo" className='w-36' /></Link>

        {/* Mobile Menu Button */}
        <button onClick={toggleMenu} className='lg:hidden ml-auto'>
          <svg className="w-7 h-7" fill="#000" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
          </svg>
        </button>

        {/* Menu Items */}
        <div className={`flex ${isMenuOpen ? 'block' : 'hidden'} lg:flex flex-col lg:flex-row gap-x-5 lg:justify-center lg:items-center max-lg:fixed max-lg:bg-white max-lg:bg-opacity-70 max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50`}>
          <button onClick={toggleMenu} className='lg:hidden fixed top-2 right-4 z-[100] rounded-full bg-white p-3'>
            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 fill-black" viewBox="0 0 320.591 320.591">
              <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" data-original="#000000"></path>
              <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" data-original="#000000"></path>
            </svg>
          </button>
          
          <ul className='flex flex-col lg:flex-row lg:gap-x-5 gap-y-3 lg:justify-center'>
            <li>
              <Link to='/' onClick={handleMenuItemClick} className='hover:text-[#d400ff73] text-[#d400ff] block font-semibold text-[15px]'>Home</Link>
            </li>
            <li>
              <Link to='/blog' onClick={handleMenuItemClick} className='hover:text-[#d400ff73] text-[#d400ff] block font-semibold text-[15px]'>Blog</Link>
            </li>
            <li>
              <Link to='/contact' onClick={handleMenuItemClick} className='hover:text-[#d400ff73] text-[#d400ff] block font-semibold text-[15px]'>Contact</Link>
            </li>
          </ul>
          
          {/* Login/Register Button */}
          <div className='flex justify-center mt-4 lg:mt-0'>
            <Link to='/auth' onClick={handleMenuItemClick} className='btn btn-sm w-32 px-4 py-3 text-sm rounded-full font-bold text-white bg-[#d400ff]'>
              Login/Register
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
