import React from 'react';


const Services = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-white to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      
      {/* Services Title */}
      <h1 className="text-5xl font-bold text-gray-900 text-center mt-2">Services I Offer</h1>

      <p className="text-lg text-gray-600 mt-6 text-center max-w-3xl">
        With over 7 years of experience in the tech industry, I offer a variety of services that cater to individuals and businesses, helping them grow and achieve their goals with cutting-edge technology solutions.
      </p>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mt-10 w-full lg:w-3/4">
        
         {/* Software Development Service */}
         <div className="border rounded-lg p-6 shadow-md bg-white hover:border-[#e42ff5] transition duration-300">
          <div className="flex items-center mb-4">
            <h4 className="text-xl font-semibold text-black">Software Development</h4>
          </div>
          <p className="text-gray-500">
            I specialize in building robust, scalable, and efficient software applications tailored to meet your specific business needs. Whether it's web, mobile, or desktop applications, I deliver end-to-end solutions using modern technologies like Python, JavaScript, and cloud services.
          </p>
        </div>

        {/* Cloud Services */}
        <div className="border rounded-lg p-6 shadow-md bg-white hover:border-[#e42ff5] transition duration-300">
          <div className="flex items-center mb-4">
          
            <h4 className="text-xl font-semibold text-black">Cloud Solutions</h4>
          </div>
          <p className="text-gray-500">
            Leveraging AWS and Google Cloud, I provide scalable, secure cloud-based solutions, from architecture design to deployment, ensuring your applications run efficiently with continuous uptime.
          </p>
        </div>

        {/* Mentoring */}
        <div className="border rounded-lg p-6 shadow-md bg-white hover:border-[#e42ff5] transition duration-300">
          <div className="flex items-center mb-4">
            
            <h4 className="text-xl font-semibold text-black">Mentoring</h4>
          </div>
          <p className="text-gray-500">
            I am passionate about helping aspiring developers and professionals through mentoring and tutoring. Whether you need guidance on web development or cloud services, I am here to help you on your journey.
          </p>
        </div>

        {/* Consulting */}
        <div className="border rounded-lg p-6 shadow-md bg-white hover:border-[#e42ff5] transition duration-300">
          <div className="flex items-center mb-4">
            <h4 className="text-xl font-semibold text-black">Tech Consulting</h4>
          </div>
          <p className="text-gray-500">
            Offering strategic technical consulting to help businesses optimize their tech infrastructure and choose the best tools for their needs. From cloud adoption to software development, I provide expert advice to ensure you're using the most effective solutions.
          </p>
        </div>
      </div>

    </div>
  );
};

export default Services;
