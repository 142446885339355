import React from 'react';
import avatar from '../assets/img/croppedavatar.png';
import About from '../components/About';
import Services from './Services';
import WorkWithMe from './WorkWithMe';
import CV from '../assets/Molato_CV.pdf';

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-6 font-inter">
      {/* Avatar */}
      <div className="rounded-full overflow-hidden w-40 h-40 mb-6 shadow-lg">
        <img src={avatar} alt="Molato's Avatar" className="w-full h-full object-cover" />
      </div>

      {/* Greeting */}
      <h2 className="text-4xl font-semibold mb-2 text-black text-center">Hi! I am Molato</h2>

      {/* Header Tagline with smaller size */}
      <h1 className="text-4xl sm:text-5xl font-bold text-gray-800 mb-4 animate-fade-slide-up text-center">
        Software Developer based in Pretoria
      </h1>

      {/* Small Text */}
      <p className="text-center text-lg font-medium text-gray-600 mb-8 max-w-xl leading-relaxed">
        I am a software developer from Pretoria, South Africa with 8 years of experience in multiple industries like Insurance, Consultancy, and Military background.
      </p>

      {/* Buttons */}
      <div className="flex flex-col sm:flex-row gap-4 justify-center">
        <a
          href="#contact"
          className="btn text-white px-6 py-3 rounded-full shadow-md hover:bg-blue-700 transition duration-300"
        >
          Contact Me
        </a>
        <a
          href={CV} // Replace with actual resume file path
          className="flex items-center bg-transparent border-2 border-black text-black px-6 py-3 rounded-full hover:text-[#e42ff5] transition duration-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M8 3a2 2 0 114 0v5a2 2 0 11-4 0V3zM5 10a1 1 0 00-1 1v2a2 2 0 102 0v-2a1 1 0 00-1-1zM13 10a1 1 0 00-1 1v2a2 2 0 102 0v-2a1 1 0 00-1-1z" />
            <path fillRule="evenodd" d="M4 12v2a4 4 0 008 0v-2H4zm8 4H4v2h8v-2z" />
          </svg>
          My Resume
        </a>
      </div>
      <About />
      <Services />
      <WorkWithMe />
    </div>
  );
};

export default Home;
